import React from "react";

const WhatsAppButton = () => {
  const phoneNumber = "905375418777";

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#25D366",
        padding: "10px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <img
        src={process.env.PUBLIC_URL + "/assets/img/whatsapp.svg"}
        alt="WhatsApp"
        style={{
          width: "40px",
          height: "40px",
        }}
      />
    </a>
  );
};

export default WhatsAppButton;
