import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import ShopGridNoSidebar from "./pages/shop/ShopGridNoSidebar";
import OrderSearch from "./pages/other/OrderSearch";
import WhatsAppButton from "./components/WhatsAppButton";

const Anasayfa = lazy(() => import("./pages/home/HomeFurnitureFour"));
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const BlogNoSidebar = lazy(() => import("./pages/sss/BlogNoSidebar"));
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const EmailVerification = lazy(() => import("./pages/other/EmailVerification"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Order = lazy(() => import("./pages/other/Order"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const Mesafeli = lazy(() => import("./pages/other/Mesafeli"));
const Iade = lazy(() => import("./pages/other/Iade"));
const UyelikSozlesmesi = lazy(() => import("./pages/other/UyelikSozlesmesi"));
const GizlilikSozlesmesi = lazy(() =>
  import("./pages/other/GizlilikSozlesmesi")
);

function checkUserAuthentication() {
  const userId = localStorage.getItem("ID");
  return userId !== null;
}

const App = (props) => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          tr: require("./translations/turkish.json"),
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={Anasayfa}
                />
                {/* Homepages */}
                <Route
                  path={process.env.PUBLIC_URL + "/Anasayfa"}
                  component={Anasayfa}
                />
                {/* Shop pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/Koleksiyon"}
                  component={ShopGridStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Search"}
                  component={ShopGridNoSidebar}
                />
                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  component={ProductSticky}
                />
                {/* Blog pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/SSS"}
                  component={BlogNoSidebar}
                />
                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/Hakkımızda"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Mesafeli-Satis-Sözlesmesi"}
                  component={Mesafeli}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Iade-Kosullari"}
                  component={Iade}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Gizlilik-Sozlesmesi"}
                  component={GizlilikSozlesmesi}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Uyelik-Sozlesmesi"}
                  component={UyelikSozlesmesi}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/İletişim"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Email-Verification"}
                  component={EmailVerification}
                />
                {/* <Route
                  path="/Hesabım"
                  render={() =>
                    checkUserAuthentication() ? (
                      <Route component={MyAccount} />
                    ) : (
                      <Route component={LoginRegister} />
                    )
                  }
                /> */}

                <Route
                  path="/Uyelik"
                  render={() =>
                    checkUserAuthentication() ? (
                      <Route component={MyAccount} />
                    ) : (
                      <Route component={LoginRegister} />
                    )
                  }
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Sepet"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/compare"}
                  component={Compare}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/order"}
                  component={Order}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-order"}
                  component={OrderSearch}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ForgotPassword"}
                  component={ForgotPassword}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
      <WhatsAppButton />
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
